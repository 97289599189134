import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/net-framework/net-framework-qna",
  "date": "2013-07-07",
  "title": ".NET FRAMEWORK Q&A",
  "author": "admin",
  "tags": ["development", ".net-framework"],
  "featuredImage": "feature.jpg",
  "excerpt": "In this article, we are discussing few common .NET frequently asked questions and answers."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`The differences between the `}<inlineCode parentName="h2">{`Dispose()`}</inlineCode>{` and `}<inlineCode parentName="h2">{`Finalize()`}</inlineCode></h2>
    <p>{`CLR uses the Dispose and Finalize methods to perform garbage collection of run-time objects of .NET applications.`}</p>
    <p>{`The `}<strong parentName="p">{`Finalize`}</strong>{` method is called automatically by the runtime. CLR has a garbage collector (GC), which periodically checks for objects in heap that are no longer referenced by any object or program. It calls the Finalize method to free the memory used by such objects.`}</p>
    <p>{`The `}<strong parentName="p">{`Dispose`}</strong>{` method is called by the programmer. Dispose is another method to release the memory used by an object. The Dispose method needs to be explicitly called in code to dereference an object from the heap. The Dispose method can be invoked only by the classes that implement the IDisposable interface.`}</p>
    <h2>{`What is code access security (CAS)?`}</h2>
    <p>{`Code access security (CAS) is part of the .NET security model that prevents unauthorized access of resources and operations, and restricts the code to perform particular tasks.`}</p>
    <h2>{`How can you turn-on and turn-off CAS?  `}</h2>
    <p>{`You can use the Code Access Security Tool (Caspol.exe) to turn security on and off.`}</p>
    <p>{`To turn off security, type the following command at the command prompt:`}</p>
    <p><inlineCode parentName="p">{`caspol -security off`}</inlineCode>{` `}</p>
    <p>{`To turn on security, type the following command at the command prompt:`}</p>
    <p><inlineCode parentName="p">{`caspol -security on`}</inlineCode>{` `}</p>
    <p>{`In the .NET Framework 4.0, for using Caspol.exe, you first need to set the `}<inlineCode parentName="p">{`<LegacyCasPolicy>`}</inlineCode>{` element to true.`}</p>
    <h2>{`Differences between managed and unmanaged Code`}</h2>
    <p>{`Managed code is the code that is executed directly by the CLR instead of the operating system. The code compiler first compiles the managed code to intermediate language (IL) code, also called as MSIL code. This code doesn't depend on machine configurations and can be executed on different machines.`}</p>
    <p>{`Unmanaged code is the code that is executed directly by the operating system outside the CLR environment. It is directly compiled to native machine code which depends on the machine configuration.`}</p>
    <p>{`In the managed code, since the execution of the code is governed by CLR, the runtime provides different services, such as garbage collection, type checking, exception handling, and security support. These services help provide uniformity in platform and language-independent behavior of managed code applications. In the unmanaged code, the allocation of memory, type safety, and security is required to be taken care of by the developer. If the unmanaged code is not properly handled, it may result in memory leak. Examples of unmanaged code are ActiveX components and Win32 APIs that execute beyond the scope of native CLR.`}</p>
    <h2>{`What are tuples?`}</h2>
    <p>{`Tuple is a `}<strong parentName="p">{`fixed-size collection`}</strong>{` that can have elements of either same or different data types.  
Similar to arrays, a user must have to specify the size of a tuple at the time of declaration.`}</p>
    <p>{`Tuples are allowed to hold up from 1 to 8 elements and if there are more than 8 elements, then the 8th element can be defined as another tuple.`}</p>
    <p>{`Tuples can be specified as `}<strong parentName="p">{`parameter`}</strong>{` or `}<strong parentName="p">{`return type`}</strong>{` of a method.`}</p>
    <h2>{`What is garbage collection? What is the difference between garbage collections in .NET 4.0 and earlier versions.`}</h2>
    <p>{`Garbage collection prevents memory leaks during execution of programs.`}</p>
    <p>{`Garbage collector is a low-priority process that manages the allocation and deallocation of memory for your application. It checks for the unreferenced variables and objects. If GC finds any object that is no longer used by the application, it frees up the memory from that object.`}</p>
    <p>{`GC has changed a bit with the introduction of .NET 4.0. In .NET 4.0, the GC.Collect() method contains the following overloaded methods: `}<inlineCode parentName="p">{`GC.Collect(int), GC.Collect(int, GCCollectionMode)`}</inlineCode>{` Another new feature introduced in .NET is to notify you when the GC.Collect() method is invoked and completed successfully by using different methods.  .NET 4.0 supports a new background garbage collection that replaces the concurrent garbage collection used in earlier versions.        This concurrent GC allocates memory while running and uses current segment (which is 16 MB on a workstation) for that. After that, all threads are suspended. In case of background GC, a separate ephemeral GC - gen0 and gen1 can be started, while the full GC - gen0, 1, and 2 - is already running. `}</p>
    <h2>{`How does CAS work?`}</h2>
    <p>{`There are two key concepts of CAS security policy-code groups and permission**s. A code group contains assemblies in it in a manner that each .NET assembly is related to a particular code group and some permissions are granted to each code group. For example, using the default security policy, a control downloaded from a Web site belongs to the Zone, Internet code group, which adheres to the permissions defined by the named permission set. (Normally, the named permission set represents a very restrictive range of permissions.)`}</p>
    <p>{`Assembly execution involves the following steps:`}</p>
    <ul>
      <li parentName="ul">{`Evidences are gathered about assembly.`}</li>
      <li parentName="ul">{`Depending on the gathered evidences, the assembly is assigned to a code group.`}</li>
      <li parentName="ul">{`Security rights are allocated to the assembly, depending on the code group.`}</li>
      <li parentName="ul">{`Assembly runs as per the rights assigned to it.`}</li>
    </ul>
    <h2>{`What is difference between NameSpace and Assembly?`}</h2>
    <p>{`Following are the differences between namespace and assembly:`}</p>
    <ul>
      <li parentName="ul">{`Assembly is physical grouping of logical units, Namespace, logically groups classes.`}</li>
      <li parentName="ul">{`Namespace can span multiple assemblies`}</li>
    </ul>
    <h2>{`Mention the execution process for managed code.`}</h2>
    <p>{`A piece of managed code is executed as follows:`}</p>
    <ul>
      <li parentName="ul">{`Choosing a language compiler`}</li>
      <li parentName="ul">{`Compiling the code to MSIL`}</li>
      <li parentName="ul">{`Compiling MSIL to native code`}</li>
      <li parentName="ul">{`Executing the code.`}</li>
    </ul>
    <h2>{`What is the difference between LINQ `}<strong parentName="h2">{`Query syntax`}</strong>{` and `}<strong parentName="h2">{`Method syntax`}</strong>{`?`}</h2>
    <ol>
      <li parentName="ol">{`Query expression must be translated into method syntax at the compiled time (deferred execution), but Method expressions get called immediately.
2. Query syntax is more readable and concise than the Method syntax.
3. Not every  LINQ operator is available in Query syntax`}</li>
    </ol>
    <h2>{`What is a Function predicate?`}</h2>
    <p>{`A `}<strong parentName="p">{`predicate`}</strong>{` is a function that returns `}<inlineCode parentName="p">{`true`}</inlineCode>{` or `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <p>{`A `}<strong parentName="p">{`predicate delegate`}</strong>{` is a reference to a predicate.`}</p>
    <p>{`So basically a predicate delegate is a reference to a function that returns `}<inlineCode parentName="p">{`true`}</inlineCode>{` or `}<inlineCode parentName="p">{`false`}</inlineCode>{`.
Predicates are very useful for filtering a list of values.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`using System;
using System.Collections.Generic;

class Program
{
    static void Main()
    {
        List<int> list = new List<int> { 1, 2, 3 };

        Predicate<int> predicate = new Predicate<int>(greaterThanTwo);

        List<int> newList = list.FindAll(predicate);
    }

    static bool greaterThanTwo(int arg)
    {
        return arg > 2;
    }
}
`}</code></pre>
    <h2>{`What is an `}<strong parentName="h2">{`Action`}</strong>{`, `}<strong parentName="h2">{`Func`}</strong>{` and  `}<strong parentName="h2">{`Predicate`}</strong>{`?`}</h2>
    <p><strong parentName="p">{`Action`}</strong>{` is a delegate (pointer) to a method, that takes zero, one or more input parameters, but does not return anything.`}</p>
    <p><strong parentName="p">{`Func`}</strong>{` is a delegate (pointer) to a method, that takes zero, one or more input parameters, and returns a value (or refence).`}</p>
    <p><strong parentName="p">{`Predicate`}</strong>{` is a special kind of Func often used for comparisons, returning a boolean.`}</p>
    <p>{`Though widely used with Linq, Action and Func are concepts logically independent of Linq. C++ already contained the basic concept in form of typed function pointers.`}</p>
    <p>{`Here is a small example for Action and Func without using Linq:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`class Program
{
    static void Main(string[] args)
    {
        Action<int> myAction = new Action<int>(DoSomething);
        myAction.Invoke(123);           // Prints out "123"

        Func<int, double> myFunc = new Func<int, double>(CalculateSomething);
        Console.WriteLine(myFunc(5));   // Prints out "2.5"
    }

    static void DoSomething(int i)
    {
        Console.WriteLine(i);
    }

    static double CalculateSomething(int i)
    {
        return (double)i/2;
    }
}
`}</code></pre>
    <h2>{`What is a Delegate?`}</h2>
    <p>{`Object-oriented, type-safe function pointer.`}</p>
    <p>{`Delegates the responsibility for an operation to another function. Defined with:`}</p>
    <ul>
      <li parentName="ul">{`A named method`}</li>
      <li parentName="ul">{`A delegate expression`}</li>
      <li parentName="ul">{`A lambda expression (Unnamed, inline function)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      